import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';

const EpdCompilerPdfPreview: React.FunctionComponent = () => {
  const history = useHistory();
  const { id } = useParams<any>();
  useEffect(() => {
    const checkAuthentication = async () => {
      const route = EpdLinks.epdCompilerPdfPreviewAuth(id);
      const isAuthenticated = AuthService.isLoggedin();
      if (isAuthenticated) {
        history.push(route);
      } else {
        await AuthService.login({ userName: 'pdfgenerator@sigma.software', password: 'qQ1!2@' });
        history.push(route);
      }
    };

    checkAuthentication();
  }, []);

  return <div>Loading...</div>;
};

export default EpdCompilerPdfPreview;
